import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";

import { BootStrapper, THEME } from "@thucydides/app-components";

import { ALL_ROUTES } from "../pages/routes";

type AdditionalWrappersProp = {
  children: () => JSX.Element;
};

const AdditionalWrappers = ({ children }: AdditionalWrappersProp) => (
  <ChakraProvider theme={THEME}>{children}</ChakraProvider>
);

const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <BootStrapper
        appName="home"
        routes={ALL_ROUTES}
        AdditionalWrappers={AdditionalWrappers}
      />
    </QueryClientProvider>
  );
};

export default App;
