import { LazyExoticComponent } from "react";
import { HOME_ROUTE } from "./home/route";
import { DETAIL_ROUTE } from "./detail/route";

export type routeType = {
  Component: LazyExoticComponent<<T>(props: T) => JSX.Element>;
  path: string;
};

export const ALL_ROUTES: routeType[] = [HOME_ROUTE, DETAIL_ROUTE];
